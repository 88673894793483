<template>
  <section class="checkCandidate-index">
    <div class="header" v-auth="'update,check,upload,detail,import'">
      <el-button
        :type="table.query.bmfs==='1'?'primary':''"
        size="small"
        :class="table.query.bmfs==='1'?'':'btn_active'"
        @click="changeType('1')"
      >随机抽选</el-button>
      <el-button
        :type="table.query.bmfs==='2'?'primary':''"
        size="small"
        :class="table.query.bmfs==='2'?'':'btn_active'"
        @click="changeType('2')"
      >个人申请</el-button>
      <el-button
        :type="table.query.bmfs==='3'?'primary':''"
        size="small"
        :class="table.query.bmfs==='3'?'':'btn_active'"
        @click="changeType('3')"
      >组织推荐</el-button>
    </div>

    <div class="search-component">
      <div class="title">数据筛选</div>
      <div class="header-container">
        <div class="item">
          <div class="text">年度：</div>
          <el-date-picker
            v-model="table.query.nd"
            value-format="yyyy"
            type="year"
            size="small"
            placeholder="请选择年度"
            @change="changeNd"
          ></el-date-picker>
        </div>
        <div class="item">
          <div class="text">快速搜索：</div>
          <el-input v-model="table.query.keyword" placeholder="请输入关键词" size="small"></el-input>
        </div>
        <div class="item">
          <div class="text">审查状态：</div>
          <el-select v-model="table.query.sczt" placeholder="请选择审查状态" @change="changeSczt" size="small">
            <el-option
              v-for="item in table.options.sczt.children"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <el-button type="primary" class="keyword" size="small" @click="search">查询</el-button>
        <el-button size="small" @click="reset">重置</el-button>
      </div>
    </div>
    <div class="main">
      <div class="main-header">
        <div class="router2-name">候选人列表</div>
        <div class="btn">
          <!-- <el-button type="primary" size="small">分类统计</el-button> -->
          <el-button
              @click="importFile"
              type="primary"
              size="small"
              v-if="table.query.bmfs!=1 && auth.import"
          >导入</el-button>
          <el-button type="primary" size="small" @click="changeComponent('checklot')" v-if="auth.check">批量审查</el-button>

          <el-button size="small" @click="exportExcel">导出EXCEL</el-button>
        </div>
      </div>
      <div class="table">
        <el-table
          v-loading="table.loading"
          :data="table.data"
          row-key="id"
          height="100%"
          :header-cell-style="{'text-align':'center','background-color':'#FAFAFA'}"
          :cell-style="{'text-align':'center'}"
        >
          <el-table-column type="index" label="序号" :index="indexMath"></el-table-column>
          <el-table-column prop="nd" label="年度"></el-table-column>
          <el-table-column prop="sfzhm" label="身份证号码"></el-table-column>
          <el-table-column prop="xm" label="姓名">
            <template slot-scope="scope">
              <span class="blue" @click="showInfo(scope.row)">{{scope.row.xm}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="xb" label="性别"></el-table-column>
          <el-table-column prop="bmfs_a" label="报名方式"></el-table-column>
          <el-table-column prop="csrq" label="出生日期">
            <template slot-scope="scope">
              <span v-if="scope.row.csrq">{{scope.row.csrq}}</span>
              <span v-else>{{scope.row.sfzhm|csrq}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="lxfs" label="联系方式"></el-table-column>
          <el-table-column prop="cjzd" label="常住地址"></el-table-column>
          <el-table-column prop="sczt_a" label="审查状态">
            <template slot-scope="scope">
              <span v-if="scope.row.sczt=== 3 || scope.row.sczt === 5" class="red">{{scope.row.sczt_a}}</span>
              <span v-if="scope.row.sczt === 2 || scope.row.sczt ===4" class="green">{{scope.row.sczt_a}}</span>
              <span v-if="scope.row.sczt === 1 " class="yellow">{{scope.row.sczt_a}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="操作">
            <template slot-scope="scope">
              <el-button
                @click="changeComponent('edit',scope.row,'update')"
                type="text"
                size="small"
                v-if="auth.update && auth.upload && auth.detail"
              >修改</el-button>
              <el-button @click="exportPDF(scope.row)" type="text" size="small">导出PDF</el-button>
              <el-button
                @click="changeComponent('checkone',scope.row)"
                type="text"
                size="small"
                v-if="(scope.row.sczt===1 || scope.row.sczt === 2 || scope.row.sczt === 3) && auth.check "
              >审查</el-button>
              <el-button
                @click="changeComponent('checkone',scope.row)"
                type="text"
                size="small"
                v-if="(scope.row.sczt ===4 || scope.row.sczt ===5)  && auth.check"
              >重新审查</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="table.query.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="table.query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="table.total"
      ></el-pagination>
    </div>
    <personInfo v-if="showPerson" ref="personInfo" @closeInfo="closeInfo"></personInfo>
    <import-exlex ref="exlexDom" @showProgress="showProgress" @getProgress="getProgress"
                  @setProgress="setProgress"
                    :bmfs="table.query.bmfs"></import-exlex>
    <import-progress @closeDialog="closeDialog" ref="progressDom" @changeBackground="changeBackground" :progress="progress" :background="background"></import-progress>
    <import-success @closeExlex="closeExlex" ref="successDialog" @failDialog="failDialog" :progress="progress"></import-success>
    <import-fail ref="failDialog" @closeAllDislog="closeAllDislog" :progress="progress"></import-fail>
    <div class="background" v-show="background && progress && !progress.finish ">
      <div class="title">数据导入中</div>
      <div class="text">导入数据共{{progress.total}}条，已处理{{progress.handle}}条，失败{{progress.bad}}条;</div>
      <div class="text">正在导入，请稍后...</div>
      <el-button type="text" @click="changeBackground(false,'progressDom')">恢复窗口</el-button>
    </div>
    <div class="background_Success" v-show="background && progress.finish">
      <div class="title">数据导入完成</div>
      <div class="text">导入数据共{{progress.total}}条，已处理{{progress.handle}}条，失败{{progress.bad}}条;</div>
      <div class="text">用时{{progress.past_seconds}}秒</div>
      <el-button type="text" @click="changeBackground(false,'successDialog')">恢复窗口</el-button>
    </div>

    <component :is="componentName" @getList="getList" ref="component" v-if="show" :bmfs="this.table.query.bmfs" @changeShow="changeShow"></component>
  </section>
</template>

<script>
import numberToString from '@/plugin/numberToString';
import Candidate from '@/api/choose/candidate';
import checkone from './components/checkone';
import checklot from './components/checklot';
import importExlex from "@/views/choose/checkCandidateQX/components/index/components/importExlex";
import importProgress from "@/views/choose/checkCandidateQX/components/index/components/importProgress";
import importSuccess from "@/views/choose/checkCandidateQX/components/index/components/importSuccess";
import importFail from "@/views/choose/checkCandidateQX/components/index/components/importFail";
import Public from "@/api/public";
export default {
  name: '',
  props: { dic: Object },
  components: { checkone, checklot ,importExlex,importProgress,importSuccess,importFail},
  data() {
    return {
      type: '随机抽选',
      table: {
        data: [],
        query: {
          keyword: '',
          page: 1,
          size: 100,
          nd: new Date().getFullYear().toString(), //年度
          bmfs: '1', //报名方式  1-随机抽选|2-个人申请|3-组织推荐
          sczt: '', //审查状态，多个状态用英文逗号隔开
        },
        loading: false,
        total: 0,
        options: {
          sczt: {
            children: [],
          },
          bmfs: {
            children: [],
          },
        },
      },
      progress: {}, //进度条
      timeout: '', //进度定时器
      background: false,
      componentName: '',
      show: false,
      auth: {
        update: true,
        check: true,
        upload: true,
        detail: true,
        import:true
      },
      PDFinfo: {
        length: '',
        data: [],
        name: '',
      },
      showPerson: false,
    };
  },
  methods: {
    changeBackground(background, name) {
      this.background = background;
      if (!background) {
        // this.$store.commit('changeComponent', name);
        this.$ref[name].show = true
      }
    },
    clear() {
      clearInterval(this.timeout);
    },
    setProgress(progress) {
      this.progress = progress;
    },
    getProgress() {
      clearInterval(this.timeout);
      this.timeout = setInterval(() => {
        Public.components({ ImportHxr: 'hxr' }).then((res) => {
          if (!res.ImportHxr) {
            clearInterval(this.timeout);
            return;
          } else {
            console.log(res)
            this.progress = res.ImportHxr;
            if (res.ImportHxr.finish) {
              this.$refs.successDialog.show=true
              // this.$store.commit('changeComponent', 'importSuccess');
              clearInterval(this.timeout);
            }else if ( !res.ImportHxr.finish&&!this.background) {
              this.$refs.progressDom.show=true
              // this.$store.commit('changeComponent', 'importProgress');
            } else {
              this.closeAllDislog()
              this.$store.commit('changeComponent', '');
            }


          }
        });
      }, 2000);
    },
    showProgress(){
      this.$refs.progressDom.show=true
    },
    closeDialog(){
      this.$refs.progressDom.show=false
    },
    closeExlex(){
      this.$refs.exlexDom.toggle(false)
    },
    failDialog(){
      this.$refs.failDialog.show = true
    },
    closeAllDislog(){
      this.$refs.exlexDom.toggle(false)
      this.$refs.failDialog.show = false
      this.$refs.progressDom.show=false
    },
    showInfo(data) {
      this.showPerson = true;
      this.$nextTick(() => {
        this.$refs.personInfo.toggle(data);
      });
    },
    closeInfo() {
      this.showPerson = false;
    },
    importFile() {
      let year = new Date()
      // console.log(year.getFullYear())
      this.$store.commit('setYear', year.getFullYear().toString());
      this.$refs.exlexDom.toggle(true)
    },
    exportExcel() {
      this.$store.commit('changeLoading', {
        loading: true,
        title: '正在导出excel,由于数据可能较多，请稍后',
        color: 'rgba(255,255,255,0.8)',
      });
      let { total } = this.table;
      let { keyword, bmfs, sczt } = this.table.query;
      Candidate.list({
        keyword,
        bmfs,
        sczt,
        size: total,
        page: 1,
      }).then((res) => {
        // 将审查状态由数字 变为可读的中文
        let data = numberToString(res.items, 'sczt', this.table.options.sczt.children);
        data = numberToString(data, 'bmfs', this.table.options.bmfs.children);
        let cou = [
          { label: '序号', key: 'index', length: 100 },
          { label: '年度', key: 'nd', length: 100 },
          { label: '身份证号码', key: 'sfzhm', length: 180 },
          { label: '姓名', key: 'xm', length: 100 },
          { label: '性别', key: 'xb', length: 100 },
          { label: '报名方式', key: 'bmfs_a', length: 100 },
          { label: '出生日期', key: 'csrq', length: 100 },
          { label: '联系方式', key: 'sjhm', length: 120 },
          { label: '常住地址', key: 'cjzd', length: 400 },
          { label: '审查状态', key: 'sczt_a', length: 130 },
        ]
        // this.$exportExcel({
        //   name: `候选人列表--包含审核状态(${res.total}条)`,
        //   data: data,
        //   header: [
        //     { label: '序号', key: 'index', length: 100 },
        //     { label: '年度', key: 'nd', length: 100 },
        //     { label: '身份证号码', key: 'sfzhm', length: 180 },
        //     { label: '姓名', key: 'xm', length: 100 },
        //     { label: '性别', key: 'xb', length: 100 },
        //     { label: '报名方式', key: 'bmfs_a', length: 100 },
        //     { label: '出生日期', key: 'csrq', length: 100 },
        //     { label: '联系方式', key: 'sjhm', length: 120 },
        //     { label: '常住地址', key: 'cjzd', length: 400 },
        //     { label: '审查状态', key: 'sczt_a', length: 130 },
        //   ],
        // });

        this.$exportExcel(cou,data,`候选人列表--包含审核状态(${res.total}条)`)
      });
    },
    exportPDF(data) {
      window.removeEventListener('message', this.message);
      this.PDFinfo = {
        length: '',
        data: [],
        name: data.xm,
      };
      this.$store.commit('changeLoading', {
        loading: true,
        title: `正在导出${data.xm}的PDF,请稍后`,
        color: 'rgba(255,255,255,1)',
      });
      this.$emit('changeComponent', 'exportPDF', data);
      window.addEventListener('message', this.message);
    },
    message(e) {
      if (e.data.type === 'PDF') {
        let { data } = e.data;
        switch (data.type) {
          case 'length': {
            if (data.data === 0) {
              this.$getPdf(this.PDFinfo.name + '的个人资料', '.exportPDF');
            }
            this.PDFinfo.length = data.data;
            break;
          }
          case 'ok': {
            console.log(data);
            let index = this.PDFinfo.data.find((item) => item === data.data);
            if (index > -1) {
              return;
            } else {
              this.PDFinfo.data.push(data.data);
              if (this.PDFinfo.data.length === this.PDFinfo.length && this.PDFinfo.data.length !== 0) {
                this.$getPdf(this.PDFinfo.name + '的个人资料', '.exportPDF');
              }
            }
            break;
          }
          case 'download': {
            window.removeEventListener('message', this.message);
            this.$store.commit('changeLoading', { loading: false, title: '' });
            this.$emit('changeComponent', 'index');
            break;
          }
        }
      }
    },
    indexMath(index) {
      let { page, size } = this.table.query;
      return index + 1 + (page - 1) * size;
    },
    // 修改component隐藏
    changeShow(show) {
      this.show = show;
    },
    // 修改年度
    changeNd() {
      if (this.table.query.nd) {
        return this.getList();
      }
    },
    // 改变审查状态
    changeSczt() {
      if (this.table.query.sczt !== '') {
        return this.getList();
      }
    },
    // 报名方式改变
    changeType(type) {
      if (type === this.type) {
        return;
      }
      this.table.query.bmfs = type;
      console.log(this.table)
      return this.getList();
    },
    // 每页条数变化
    handleSizeChange(data) {
      this.table.query.size = data;
      this.page = 1;
      return this.getList();
    },
    // 当前页数变化
    handleCurrentChange(data) {
      if (data === this.table.query.page) {
        return;
      } else {
        this.table.query.page = data;
        return this.getList();
      }
    },
    reset() {
      this.table.query = {
        page: 1,
        size: 100,
        keyword: '',
        sczt: '',
        bmfs: 1,
        nd: new Date().getFullYear().toString(),
      };
      return this.getList();
    },
    search() {
      return this.getList();
    },
    getList() {
      this.table.loading = true;
      Candidate.list(this.table.query)
        .then((res) => {
          // 将审查状态由数字 变为可读的中文
          let data = numberToString(res.items, 'sczt', this.table.options.sczt.children);
          data = numberToString(data, 'bmfs', this.table.options.bmfs.children);
          this.table.data = data;
          this.table.total = res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    changeComponent(name, data) {
      if (name === 'edit') {
        this.$emit('changeComponent', 'edit', data);
      } else {
        this.componentName = name;
        this.show = true;
        this.$nextTick(() => {
          this.$refs.component.toggle(true, data);
        });
      }
    },
    toggle() {
      this.table.options = this.dic;
      return this.getList();
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
  beforeDestroy() {
    window.removeEventListener('message', this.message);
  },
};
</script>

<style scoped lang="scss">
.checkCandidate-index {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  .header {
    margin-bottom: 20px;
  }
  .search-component {
    background-color: #fff;
    margin-bottom: 20px;
    .title {
      color: $h4;
      border-bottom: 1px solid $border1;
      padding: 15px 30px;
      font-weight: bold;
    }
    .header-container {
      padding: 15px 30px;
      display: flex;
      align-items: center;
      .item {
        display: flex;
        align-items: center;
      }
      .keyword {
        margin-left: 20px;
      }
      .text {
        min-width: 70px;
        font-size: 14px;
        color: #666666;
      }
      .el-input {
        width: 224px;
        margin-right: 10px;
      }
    }
  }
  .main {
    background-color: #fff;
    box-sizing: border-box;
    position: relative;
    flex: 1;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .main-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      .router2-name {
        color: $h4;
        padding: 15px 0;
        font-weight: bold;
      }
      .el-button {
        margin-right: 20px;
      }
    }

    .table {
      flex: 1;
      padding-bottom: 50px;
      .el-table {
        width: calc(100% - 20px);
        border: 1px solid rgba(232, 232, 232, 1);
        border-bottom: none;
        .header-bgc {
          background-color: $border4;
        }
        .operate {
          width: 100%;
          display: flex;
        }
      }
    }
    .el-pagination {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
.red {
  color: #ec808d;
}
.green {
  color: #03b915;
}
.yellow {
  color: rgba(245, 154, 35, 64);
}
.background {
  background-color: #fff;
  position: fixed;
  z-index: 9999;
  width: 355px;
  right: 40px;
  bottom: 30px;
  font-family: 'Arial Normal', 'Arial', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  letter-spacing: normal;
  color: #333333;
  box-sizing: border-box;
  border: 1px solid rgba(233, 233, 233, 1);
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  .title {
    text-align: left;
    font-weight: bold;
    font-size: 15px;
  }
  .text {
    text-align: left;
    padding-top: 10px;
  }
  .el-button {
    text-align: right;
    padding-top: 0;
  }
}
.background_Success {
  background-color: #fff;
  position: fixed;
  z-index: 9999;
  width: 355px;
  right: 40px;
  bottom: 30px;
  font-family: 'Arial Normal', 'Arial', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  letter-spacing: normal;
  color: #333333;
  box-sizing: border-box;
  border: 1px solid rgba(233, 233, 233, 1);
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  .title {
    text-align: left;
    font-weight: bold;
    font-size: 15px;
  }
  .text {
    text-align: left;
    padding-top: 10px;
  }
  .el-button {
    text-align: right;
    padding-top: 0;
  }
}
</style>
