<template>
  <el-dialog
    title="候选人资格审查--单个"
    :visible.sync="show"
    class="checkCandidate-index-check-component"
    :append-to-body="true"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    width="600px"
    :before-close="beforeClose"
  >
    <div class="main">
      <div class="btn">
        <el-button type="primary" @click="submit(1)" size="small">审查通过</el-button>
        <el-button @click="form.ok = 0" size="small">审查不通过，选择原因</el-button>
      </div>
      <div class="item" v-if="form.ok === 0">
        <div class="text">不通过原因</div>
        <el-radio-group v-model="scbtgyy1">
          <el-radio :label="item.label" v-for="item in options" :key="item.id" @change="changeRadio()">{{item.label}}</el-radio>
        </el-radio-group>
      </div>
      <div class="item" v-if="form.ok === 0">
        <div class="text">其他原因</div>
        <el-input v-model="scbtgyy2" placeholder="不通过理由为其他时填写" size="small" :disabled="scbtgyy1 !== '其他'"></el-input>
        <el-button type="warning" size="small" @click="submit">确认审查不通过</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import Candidate from '@/api/choose/candidate';
export default {
  name: 'checkone',
  props: {},
  components: {},
  data() {
    return {
      show: false,
      loading: false,
      form: {
        ok: '',
        sfzhm: '',
        scbtgyy: '',
      },
      scbtgyy1: '',
      scbtgyy2: '',
      options: [],
    };
  },
  methods: {
    beforeClose(done) {
      console.log(2222222);
      done();
      this.$emit('changeShow', false);
    },
    changeRadio() {
      this.scbtgyy2 = '';
    },
    toggle(show, data) {
      if (!show) {
        this.$emit('getList');
        this.show = show;
        this.$emit('changeShow', false);
      } else {
        this.show = show;
        this.form.sfzhm = data.sfzhm;
      }
    },
    submit(data) {
      let { sfzhm } = this.form;
      if (data && data === 1) {
        Candidate.check({
          ok: 1,
          sfzhm,
        }).then(() => {
          this.$message.closeAll();
          this.$message.success('审核成功');
          this.toggle(false);
        });
      } else {
        if (!this.scbtgyy1) {
          this.$alert('请选择不通过原因');
          return;
        }
        if (this.scbtgyy1 === '其他' && !this.scbtgyy2) {
          this.$alert('请填写其他具体原因');
          return;
        }
        let form = Object.assign(this.form);
        if (this.scbtgyy1 === '其他' && this.scbtgyy2) {
          form.scbtgyy = this.scbtgyy2;
        } else if (this.scbtgyy1 !== '其他') {
          form.scbtgyy = this.scbtgyy1;
        }
        Candidate.check(form).then(() => {
          this.$message.closeAll();
          this.$message.success('审核成功');
          this.toggle(false);
        });
      }
    },
  },
  mounted() {},
  created() {
    let { scbtgyy } = this.$store.getters.dic;
    this.options = scbtgyy.children;
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.checkCandidate-index-check-component {
  .main {
    .btn {
      display: flex;
      justify-content: space-between;
    }
    .item {
      margin-top: 30px;
      display: flex;
      align-items: center;
      .text {
        align-self: flex-start;
        padding-top: 10px;
        margin-left: 10px;
        width: 100px;
      }
      .el-radio-group {
        display: flex;
        flex-direction: column;
        .el-radio {
          padding-top: 10px;
        }
      }
      .el-input {
        margin-right: 10px;
      }
    }
  }
}
</style>
